<template>
  <v-card class="pa-4">
    <v-card-title class="px-0 pt-2 pb-6 d-flex align-center justify-space-between">
      <span class="d-flex align-center">
        {{ $t(`task.status.${block_name}`) }} (<strong>{{ tasks.length }}</strong>)

      </span>
      <div class="d-flex align-center">
        <v-checkbox
            class="mr-6"
            v-model="by_creator_group"
            :label="$t('task.table.filter.group_by_creator')"
        ></v-checkbox>
        <v-select
            v-if="$store.getters.systemTask.table.group_by"
            class="mr-3"
            v-model="groupBy"
            :items="group_by_select"
            :label="$t('task.table.filter.group_by')"
            outlined
            hide-details
            style="max-width: 200px;"
        ></v-select>
        <v-text-field
            v-if="search_show"
            v-model="search"
            append-icon="mdi-magnify"
            :label="$t('task.search')"
            single-line
            solo
            hide-details
            style="max-width: 500px; min-width: 400px;"
        ></v-text-field>
      </div>

    </v-card-title>
    <v-data-table
        :headers="headers"
        :items="getTasks"
        :search="search"
        :custom-filter="filterOnlyCapsText"
        hide-default-footer
        disable-pagination
        class="mx-table"
        @click:row="updateModal"
        :group-by="groupBy"
    >
      <template v-slot:group.header="{items, isOpen, toggle}">
        <th @click="toggle" :colspan="headers.length">
         <div class="d-flex align-center">
           <v-icon size="18" class="mr-4">{{ isOpen ? 'fas fa-angle-up' : 'fas fa-angle-down' }}</v-icon>

           <template v-if="groupBy === 'start_time'">
             {{ items[0].start_time }}
           </template>

           <template v-else-if="groupBy === 'end_time'">
             {{ items[0].end_time }}
           </template>

           <template v-else-if="groupBy === 'importance_status'">
             {{ $t(`task.importance.${items[0].importance_status}`)}}
           </template>

           <template v-else>

             <div class="d-flex align-center">
               <UserAvatarComponent height="32" width="30" avatar_size="30" :tooltip_disabled="true" :avatar="items[0].creator.avatar" :first_name="items[0].creator.first_name" :last_name="items[0].creator.last_name"/>
               <div class="d-flex flex-column justify-center mx-task-table_item">
                 <div class="mx-task-table_item-user--name mb-n1">
                   {{ getNameSmall(items[0].creator.first_name, items[0].creator.last_name, 17) }}
                 </div>
                 <div class="mx-task-table_item-user--position">
                   {{ items[0].creator.position }}
                 </div>
               </div>
             </div>

             <!--            {{ items[0].creator.first_name }} {{ items[0].creator.last_name }}-->
           </template>
         </div>
<!--          <v-icon size="16">mdi-window-close</v-icon>-->
        </th>
      </template>

      <template v-slot:item.name="{ item }">
        <div class="mb-1 mt-3 ">
          <span class="mx-table-item__name">{{ item.name }}</span>
        </div>

        <div style="margin-top: 5px; margin-bottom: 3px;" class="d-flex align-center ">

          <div class="d-flex align-center mr-4" v-if="item.checklist_status !== null">
            <v-icon color="primary" size="12">fas fa-layer-group</v-icon>
            <span class="mx-checklist_status ml-1" >
              {{item.checklist_status}}
            </span>
          </div>

          <div class="d-flex align-center mr-4" v-if="item.comments_count > 0">
            <v-icon color="primary" size="12">far fa-comment-alt</v-icon>
            <span class="mx-checklist_status ml-1" >{{ item.comments_count }}</span>
          </div>

          <div class="d-flex align-center mr-4" v-if="item.file_path!== null">
            <v-icon color="primary" size="12">fas fa-paperclip</v-icon>
            <span class="mx-checklist_status ml-1" >1</span>
          </div>

          <div class="d-flex align-center mr-4 ml-auto" v-if="item.finish_time !== null">

            <span class="mx-checklist_status">
              <v-icon color="green" size="10">fas fa-check</v-icon>
              {{getDateFinish(item.finish_time)}} | {{ item.status_add_finish_employee_name }}
            </span>
          </div>

        </div>
      </template>

      <template v-slot:item.creator="{ item }">
        <div class="d-flex align-center my-3">
          <UserAvatarComponent height="32" width="30" avatar_size="30" :tooltip_disabled="true" :avatar="item.creator.avatar" :first_name="item.creator.first_name" :last_name="item.creator.last_name"/>
          <div class="d-flex flex-column justify-center mx-task-table_item">
            <div class="mx-task-table_item-user--name mb-n1">
              {{ getNameSmall(item.creator.first_name, item.creator.last_name, 17) }}
            </div>
            <div class="mx-task-table_item-user--position">
              {{ textSlice(item.creator.position, 22) }}
            </div>
          </div>
        </div>
      </template>

      <template v-slot:item.users="{ item }">
        <div class="d-flex flex-wrap my-3">
          <div v-for="user in item.users" :key="user.uid">
            <template v-if="item.creator.uid !== user.uid">
              <UserAvatarComponent height="32" width="30" avatar_size="30" :avatar="user.avatar" :first_name="user.first_name" :last_name="user.last_name"/>
            </template>
          </div>
        </div>
      </template>

      <template v-slot:item.observers="{ item }">
        <div class="d-flex flex-wrap my-3">
          <div v-for="user in item.observers" :key="user.uid">
            <UserAvatarComponent height="32" width="30" avatar_size="30" :avatar="user.avatar" :first_name="user.first_name" :last_name="user.last_name"/>
          </div>
        </div>
      </template>

      <template v-slot:item.importance_status="{ item }">
        <TaskImportanceLabelComponent style="font-size: 10px; padding: 5px; border-radius: 2px" :label="item.importance_status"/>
      </template>

      <template v-slot:item.checklist_status="{ item }">
       <div v-if="item.checklist_status !== null">
         <v-icon size="16">mdi-file-tree</v-icon>
         <span class="mx-checklist_status ml-1" >{{item.checklist_status}}</span>
       </div>
        <div v-if="item.checklist_status !== null">
          <v-icon size="16">mdi-message-outline</v-icon>
          <span class="mx-checklist_status ml-1" >3</span>
        </div>
      </template>

      <template v-slot:item.start_time="{ item }">
        <span class="d-flex align-center">
          <v-icon size="16" color="primary">far fa-clock</v-icon>
          <span class="ml-1">{{ getDate(item.start_time) }}</span>
        </span>
      </template>

      <template v-slot:item.end_time="{ item }">
        <span v-if="item.end_time !== null" :class="{'mx-task-table_item-date__overdue': getEndDateStatus(item.end_time) && block_name !== 'completed'}">
          <v-icon size="16" color="primary" v-if="!getEndDateStatus(item.end_time) || block_name === 'completed'">far fa-clock</v-icon>
          <span class="ml-1">{{ getDate(item.end_time) }}</span>
        </span>
        <span v-else style="width: 100%; display: block; padding-left: 16px;"></span>
      </template>

    </v-data-table>

  </v-card>

</template>

<script>
import UserAvatarComponent from "@/module/task/components/UserAvatarComponent";
import TaskImportanceLabelComponent from "@/module/task/components/Importance/TaskImportanceLabelComponent";
import moment from "moment"

export default {
  name: "TaskTableComponent",
  components: {UserAvatarComponent, TaskImportanceLabelComponent},
  props: {
    block_name: {
      type: String,
      default: 'todo',
      required: true
    },
    search_show: {
      default: true,
      required: false
    },
    tasks: {
      headers: {
        type: Array,
        default: () => [],
        required: true
      },
    },
  },
  data() {
    return {
      search: '',
      headers: [
        { text: this.$t('task.table.name'), value: 'name', width: 360 },
        // { text: this.$t('task.table.checklist_status'), value: 'checklist_status', width: 70 },
        { text: this.$t('task.table.importance_status'), value: 'importance_status', width: 150, sortable: false},
        { text: this.$t('task.table.created_by'), value: 'creator', width: 200, sortable: false },
        { text: this.$t('task.table.start_time'), value: 'start_time', width: 135 },
        { text: this.$t('task.table.end_time'), value: 'end_time', width: 145 },
        { text: this.$t('task.table.executor'), value: 'users', width: 180, sortable: false },
        { text: this.$t('task.table.observer'), value: 'observers', width: 120, sortable: false },
        // { text: this.$t('task.table.status'), value: 'status' },
        // { text: this.$t('task.table.status_percent'), value: 'status_percent' },
        // { text: this.$t('task.table.labels'), value: 'labels' },
      ],
      items: [],
      groupBy: [],
      by_creator_group: false,
      group_by_select: [
        { text: this.$t('task.table.filter.dont_group'), value: [],},
        { text: this.$t('task.table.filter.created_by'), value: 'creator.last_name',},
        { text: this.$t('task.table.filter.importance_status'), value: 'importance_status'},
        { text: this.$t('task.table.filter.start_time'), value: 'start_time'},
        { text: this.$t('task.table.filter.end_time'), value: 'end_time'},
      ]
    }
  },
  watch: {
    by_creator_group() {
      if (this.by_creator_group) {
        this.groupBy = 'creator.last_name'
      } else {
        this.groupBy = []
      }
    }
  },
  computed: {
    getTasks() {
      if (this.block_name === 'completed') {
        return this.tasks.sort((a,b) => (a.finish_time < b.finish_time) ? 1 : ((b.finish_time < a.finish_time) ? -1 : 0))
      }

      return this.tasks
    }
  },
  methods: {
    textSlice(text, slice=10) {
      if (text === null) return text
      const prefix = text.length > slice ? ' ...' : ''
      const text_sliced = text.slice(0, slice)

      return text_sliced + prefix
    },
    getNameSmall(first_name, last_name, slice=10) {
      if (first_name === null || last_name === null) {
        return text
      }

      if ((first_name + last_name).length > slice) {
        return first_name[0] + '.' + last_name
      }

      return first_name + ' ' + last_name
    },
    updateModal(e) {
      this.$store.commit('setTaskId', e.id)
      this.$store.commit('setStatusTaskUpdate', true)
    },
    getDate(date) {
      // return moment(date).format('DD.MM.YYYY') + ' | ' + moment(date).format('HH:MM')
      return moment(date).format('DD.MM.YYYY')
    },
    getDateFinish(date) {
      // return moment(date).format('DD.MM.YYYY') + ' | ' + moment(date).format('HH:MM')
      // return moment(date).format('DD.MM.YYYY HH:mm')
      return moment(date).format('DD.MM.YYYY')
    },
    color(bool) {
      return bool ? 'white' : 'primary';
    },
    getEndDateStatus(date) {
      return moment(moment().format('YYYY-MM-DD')).isAfter(date) || moment(moment().format('YYYY-MM-DD')).isSame(date)
    },
    filterOnlyCapsText (value, search, item) {
      return value != null && search != null && typeof value === 'string' &&
          value.toString().toLocaleUpperCase().indexOf(search.toLocaleUpperCase()) !== -1 ||
          item.creator.first_name.toString().toLocaleUpperCase().indexOf(search.toLocaleUpperCase()) !== -1 ||
          item.creator.last_name.toString().toLocaleUpperCase().indexOf(search.toLocaleUpperCase()) !== -1
    },
  }
}
</script>

<style lang="scss">
$border: 1px;
$border-color__light: rgba(73, 71, 71, 0.12);
$border-color__dark: rgba(215, 215, 215, 0.12);
$border-radius: 4px;
$margin-bottom: 3px;
$height-td: 52px;

.theme--light.v-data-table, .theme--dark.v-data-table {
  background-color: transparent !important;
}

.theme--light.v-data-table {
  td {
    background-color: #fff;
  }
  tr:hover {
    td:first-child {
      span {
        //text-decoration: underline !important;
      }
    }
    td {
      background-color: #f6f6f6;
    }
  }

  &.v-data-table .v-row-group__header,
  &.v-data-table .v-row-group__summary {
    background: #eeeeee !important;
    border: none !important;
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.5, 1), visibility 0s;
    button {
      transition: all 0.3s cubic-bezier(0.25, 0.8, 0.5, 1), visibility 0s;
    }
  }

  &.v-data-table > .v-data-table__wrapper > table > tbody >
  tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
    background: #e3e3e3 !important;
    //color: #fff;
    button {
      //color: #fff !important;
    }
  }


}





.theme--dark.v-data-table {
  td {
    //background-color: #fff;
  }
  tr:hover {
    td:first-child {
      span {
        text-decoration: underline !important;
      }
    }
    td {
      background-color: #252525;
    }
  }

  &.v-data-table .v-row-group__header,
  &.v-data-table .v-row-group__summary {
    background: #363636 !important;
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.5, 1), visibility 0s;
    button {
      transition: all 0.3s cubic-bezier(0.25, 0.8, 0.5, 1), visibility 0s;
    }
  }

  &.v-data-table > .v-data-table__wrapper > table > tbody >
  tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
    background: #363636 !important;
    //color: #fff;
    button {
      //color: #fff !important;
    }
  }
}

.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child) > td:not(.v-data-table__mobile-row),
.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child) > th:not(.v-data-table__mobile-row) {
  border-top: $border solid $border-color__light  !important;
  border-bottom: $border solid $border-color__light !important;
}
.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  border-top: $border solid $border-color__light  !important;
  border-bottom: $border solid $border-color__light !important;
}

.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
  border-bottom: transparent !important;
}

.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background: transparent !important;
}

.theme--dark.v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child) > td:not(.v-data-table__mobile-row),
.theme--dark.v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child) > th:not(.v-data-table__mobile-row) {
  border-top: $border solid $border-color__dark  !important;
  border-bottom: $border solid $border-color__dark !important;
}
.theme--dark.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  border-top: $border solid $border-color__dark  !important;
  border-bottom: $border solid $border-color__dark !important;
}

.theme--dark.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
  border-bottom: transparent !important;
}

.theme--dark.v-data-table > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background: transparent !important;
}

.mx-table table {
  border-spacing: 0 $margin-bottom !important;

  tr {
    cursor: pointer;
    td:first-child {
      border-left: $border solid rgba(73, 71, 71, 0.12) ;
      border-top-left-radius: $border-radius;
      border-bottom-left-radius: $border-radius;
    }
    td:last-child{
      border-right: $border solid rgba(73, 71, 71, 0.12) ;
      border-top-right-radius: $border-radius;
      border-bottom-right-radius: $border-radius;
    }
  }
  td {
    height: $height-td!important;
  }
}

.mx-table-item__name {
  text-decoration: none;
  font-weight: 500;
}

.mx-task-table_item {
  margin-left: 8px;

  &-user{
    &--name {
      font-size: 13px;
      font-weight: 700;
      color: #5e5e5e
    }
    &--position {
      font-size: 11px;
      color: #5e5e5e
    }
  }
  &-date {
    &__overdue {
      background: #c71e10;
      padding: 6px 20px;
      border-radius: 2px;
      font-size: 13px;
      color: #ffffff;
    }
  }
}

.mx-checklist_status {
  height: 15px;
  padding: 0 6px;
  border-radius: 2px;
  font-size: 10px;
  font-weight: 900;
  letter-spacing: 1px;
}

.theme--light {

  .mx-table-item__name {
    color: #4b4b4b;
  }

  .mx-table table {
    tr {
      td:first-child {
        border-color: $border-color__light;
      }
      td:last-child{
        border-color: $border-color__light;
      }
    }
  }
  .mx-task-table_item {
    &-user{
      &--name {
        color: #5e5e5e
      }
      &--position {
        color: #5e5e5e
      }
    }
  }

  .mx-checklist_status {
    background: #f2f2f2;
    color: #595959;
  }
}

.theme--dark {
  .mx-table-item__name {
    color: #bcbcbc;
  }
  .mx-table table {
    tr {
      td:first-child {
        border-color: $border-color__dark;
      }
      td:last-child{
        border-color: $border-color__dark;
      }
    }
  }
  .mx-task-table_item {
    &-user{
      &--name {
        color: #bcbcbc
      }
      &--position {
        color: #bcbcbc
      }
    }
  }
  .mx-checklist_status {
    background: #363636;
    color: #bcbcbc;
  }
}

</style>
