var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"height":"calc(100vh - 130px)","position":"relative"}},[_c('mx-preloader',{attrs:{"loader-status":_vm.preloader}}),_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"mt-6"},[_c('v-col',{attrs:{"md":"4","lg":"3"}},[_c('v-btn',{staticClass:"rounded-0 mb-6",attrs:{"to":{name: 'document.create', params: {'customer_uid': _vm.customer.uid}},"color":"primary white--text","x-large":"","block":"","elevation":"1"}},[_vm._v(" "+_vm._s(_vm.$t('document.buttons.document_create'))+" ")]),_c('v-card',{staticClass:"mx-auto",attrs:{"max-width":"400"}},[_c('div',{staticClass:"mx-menu-list"},[_vm._l((_vm.categories),function(category){return _c('button',{key:category.id,staticClass:"mx-menu-list__item",class:{'active': _vm.category_id === category.id - 1 && !_vm.customer_documents},attrs:{"disabled":category.disabled},on:{"click":function($event){return _vm.getCategoryDocument(category.id)}}},[_c('v-icon',{staticClass:"mr-2 ml-2",attrs:{"size":"24","color":"darken-4"}},[_vm._v("mdi-folder-outline")]),_vm._v(" "+_vm._s(category.name)+" ")],1)}),_c('button',{staticClass:"mx-menu-list__item cdms",class:{'active': _vm.customer_documents},on:{"click":_vm.getCustomerDocuments}},[_c('v-icon',{staticClass:"mr-2 ml-2",attrs:{"size":"24","color":"darken-4"}},[_vm._v("mdi-folder-outline")]),_vm._v(" "+_vm._s(_vm.$t('document.menu.customer_documents'))+" ")],1)],2)])],1),_c('v-col',{attrs:{"md":"8","lg":"9"}},[_c('v-text-field',{staticClass:"mb-6 elevation",attrs:{"append-icon":"mdi-magnify","label":_vm.$t('document.table.search'),"hide-details":"","solo":"","height":"53"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.getDocuments,"items-per-page":12,"search":_vm.search},scopedSlots:_vm._u([{key:"item.my",fn:function(ref){
var item = ref.item;
return [(item.created_customer_id >= 1)?_c('small',{staticStyle:{"display":"block","width":"7px","height":"7px","background":"#e3e3e3","border-radius":"2px"}}):_vm._e()]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"target":"_blank","href":_vm.options.document_url + item.path}},[_vm._v(_vm._s(item.name))])]}},{key:"item.file_type",fn:function(ref){
var item = ref.item;
return [_c('small',[_vm._v(_vm._s(_vm.getFileType(item.file_type)))])]}},{key:"item.category_id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getCategory(item.category_id))+" ")]}},{key:"item.for",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getFor(item.for))+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"text":"","elevation":"0","small":"","to":{name: 'document.edit', params: {id: item.id}}}},[_c('v-icon',{attrs:{"size":"16","color":"blue-grey darken-4"}},[_vm._v("mdi-pencil-outline")])],1),_c('v-btn',{attrs:{"text":"","elevation":"0","small":""},on:{"click":function($event){return _vm.dialogOpen(item)}}},[_c('v-icon',{attrs:{"size":"16","color":"red darken-4"}},[_vm._v("mdi-trash-can-outline")])],1)]}}])})],1)],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"500"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"pa-4"},[_c('v-card-title',{staticClass:"headline mb-9"},[_c('small',{staticStyle:{"width":"100%","text-align":"center","font-size":"14px","color":"rgba(0, 0, 0, 0.6)"}},[_vm._v(_vm._s(_vm.selected_document.name))]),_c('h4',{staticStyle:{"text-align":"center","width":"100%"}},[_vm._v(" "+_vm._s(_vm.$t('document.titles.document_delete'))+" ")])]),_c('v-card-actions',{staticClass:"d-flex justify-center"},[_c('v-btn',{attrs:{"color":"red","dark":""},on:{"click":_vm.documentDelete}},[_vm._v(" "+_vm._s(_vm.$t('document.buttons.delete'))+" ")]),_c('v-btn',{attrs:{"color":"grey","dark":""},on:{"click":_vm.dialogClose}},[_vm._v(" "+_vm._s(_vm.$t('document.buttons.abort'))+" ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }