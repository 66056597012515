<template>
  <div>
    <div class="mt-12" :style="`overflow: auto; position: relative; display: inline-block; width: 100%; max-height: ${height}px;`">
      <table class="mx-table-full"  id="company_address">
        <thead class="">
        <tr class="red">
          <th class="order-2" style="width: 180px; max-width: 180px; min-width: 180px; left: 0;">{{ $t('company.cad.title') }}</th>
          <th>{{ $t('company.cad.url') }}</th>
          <th style="min-width: 150px; max-width: 150px;">{{ $t('company.cad.login') }}</th>
          <th style="min-width: 150px; max-width: 150px;">{{ $t('company.cad.password') }}</th>
          <th style="min-width: 300px; max-width: 340px;">{{ $t('company.cad.description') }}</th>
<!--          <th style="min-width: 120px; max-width: 120px;">{{ $t('company.cad.is_active') }}</th>-->
        </tr>
        </thead>
        <tbody>
        <tr
            v-for="(item, index) in items"
            :key="index"
            :class="{'active': item.id === item_id || item.is_main === 1}"
            v-if="item.is_active === 1"
        >
          <td style="cursor:default;">{{ item.title }}</td>
          <td style="cursor:default;">
            <a :href="item.url" target="_blank">{{ item.url }}</a>
          </td>
          <td style="cursor:default;">{{ item.login }}</td>
          <td style="cursor:default;">{{ item.password }}</td>
          <td style="cursor:default;">{{ item.description }}</td>
<!--          <td style="cursor:default;">{{ item.is_active === 0 ? $t('company.no') : $t('company.yes') }}</td>-->
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>

import OpeningHours from "@/components/Company/OpeningHours";

export default {
  components: { OpeningHours },
  props: {
    cads: {
      type: Array,
      default: []
    }
  },
  data() {
    return {
      dialog: false,
      isMobile: true,
      height: 0,
      item: {},
      items: [],
      item_id: 1,
      search: {
        search: null,
        search_select: 'city'
      },
      search_from: [
        {
          text: this.$t('company.address.search.select.country'),
          value: 'country'
        },
        {
          text: this.$t('company.address.search.select.city'),
          value: 'city'
        },
        {
          text: this.$t('company.address.search.select.postcode'),
          value: 'postcode'
        },
        {
          text: this.$t('company.address.search.select.street'),
          value: 'street'
        },
      ],
    }
  },
  watch: {
    cads() {
      this.getItems()
    },
    'search.search'() {
      if (this.search.search === '') {
        this.getItems()
      }
    }
  },
  created() {
    setTimeout(this.tableHeight, 300)
    this.getItems()
  },
  mounted() {
    this.tableHeight()
    this.onResize()
    window.addEventListener('resize', this.onResize, {passive: true})
  },
  beforeDestroy() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.onResize)
    }
  },
  methods: {
    onResize() {
      this.isMobile = window.innerWidth < 600
    },
    tableHeight() {
      this.height = window.innerHeight - (document.getElementById('company_address').getBoundingClientRect().top + 40)
    },
    getItem(item) {
      this.item = item
      this.dialog = true
    },
    searchItem() {
      if (this.search.search !== null) {
        this.items = this.cads.filter(item => {
          return item[this.search.search_select].toLowerCase().indexOf(this.search.search.toLowerCase()) !== -1
        })
      }
    },
    getItems() {
      this.items = this.cads
    },
    searchClear () {
      this.getItems()
    }
  }
}
</script>
