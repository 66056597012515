<template>
  <div style="position: relative; min-height: 100vh;">
    <mx-preloader :loader-status="preloader"/>
    <MXContainerWrapper>
      <v-container fluid v-if="!preloader">
<!--        <h3 style="margin-bottom: 30px;">Tasks</h3>-->
        <v-row>
          <v-col class="mx-scrolchesh" ref="todo" cols="12" v-if="$store.getters.getTodoTasks.length !== 0">
            <TaskTableComponent block_name="todo" :tasks="$store.getters.getTodoTasks"/>
          </v-col>

          <v-col cols="12" id="in_progress" ref="in_progress" v-if="$store.getters.getInProgressTasks.length !== 0">
            <TaskTableComponent block_name="in_progress" :tasks="$store.getters.getObserveInProgressTasks"/>
          </v-col>

          <v-col cols="12" id="completed" ref="completed" v-if="$store.getters.getCompletedTasks.length !== 0">
            <TaskTableComponent block_name="completed" :tasks="$store.getters.getCompletedTasks"/>
          </v-col>
        </v-row>
        <div style="height: 65vh;"></div>
      </v-container>
    </MXContainerWrapper>
  </div>

</template>

<script>
import env from '../env';
import MXContainerWrapper from '../components/MXContainerWrapper';
import UserAvatarComponent from "@/module/task/components/UserAvatarComponent";
import TaskImportanceLabelComponent from "@/module/task/components/Importance/TaskImportanceLabelComponent";
import TaskTableComponent from "@/module/task/components/Task/TaskTableComponent";
import moment from "moment";

export default {
  name: `${env.view.name}MainView`,
  components: {MXContainerWrapper, UserAvatarComponent, TaskImportanceLabelComponent, TaskTableComponent},
  props: {
    customer: {
      default: true
    }
  },
  data() {
    return {
      preloader: true,
      scroll_element: 'todo',
    }
  },
  created() {
    this.tasksReload()
  },
  computed: {
    color() {
      if (this.taskInPercent >= 85) {
        return 'teal'
      } else if (this.taskInPercent >= 65) {
        return 'amber'
      } else {
        return 'red'
      }
    },
    taskInPercent() {
      const todo = this.percentTasksFilter(this.$store.getters.getMyTasks, true).length
      const in_progress = this.percentTasksFilter(this.$store.getters.getInProgressTasks, false).length
      const completed = this.$store.getters.getObserveTodoTasks.length + this.$store.getters.getInProgressTasks.length
      const result = (todo + in_progress + completed)

      if (completed === 0) {
        return 100
      }

      return parseInt(completed * 100 / result)
    },
  },
  methods: {
    activeElement(element) {
      return this.scroll_element === element ? 'primary': 'default'
    },
    goto(refName) {
      const element = this.$refs[refName];
      const top = element.offsetTop + -90;

      this.scroll_element = refName

      window.scrollTo({
        top: top,
        left: 0,
        behavior: 'smooth'
      });
    },
    tasksReload() {
      this.$store.dispatch('customerTask', this.customer.id).then(() => {
        setTimeout(() => {
          this.preloader = false
        }, 300)
      })
    },
    percentTasksFilter(tasks, isSame = false) {
      return tasks.filter(task => {
        return this.getEndDateStatus(task.end_time, isSame)
      })
    },
    getEndDateStatus(date, isSame) {
      if (isSame) {
        return moment(moment().format('YYYY-MM-DD')).isAfter(date)
            || moment(moment().format('YYYY-MM-DD')).isSame(date)
      }
      return moment(moment().format('YYYY-MM-DD')).isAfter(date)
    },
  }
}
</script>

<style lang="scss">
.mx-task-scroll-elements {
  border-radius: 4px
}
.mx-task-top_bar {
  position: sticky;
  top: 48px;
  z-index: 1;
}

.mx-task-top-links {
  margin-top: 6px;
  min-width: 480px;
  width: 100%;
  display: flex;
  align-items: center;

  .mx-task-top-link {
    font-size: 13px;
    margin: 0 15px 0 0;
    &-active {
      cursor: pointer;
      text-decoration: underline;
      //border-bottom: 1px solid;
    }
  }
}



.theme--light {
  .mx-task-top_bar{
    background: #ffffff;
    //background: #ffc107;
    box-shadow: 0 0 24px rgba(169, 194, 209, 0.3), 0 0 4px rgba(169, 194, 209, 0.2);
  }
  .mx-task-scroll-elements {
    background: #f3f3f3;
  }
  .mx-task-top-links {
    .mx-task-top-link {
      &-active {
        color: #fc8b5d;
        border-color: #fc8b5d;
        &:hover {
          color: #f8682e;
          border-color: #f8682e;
        }
      }
    }
  }
}

.theme--dark {
  .mx-task-top_bar{
    background: #1e1e1e;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  }
  .mx-task-scroll-elements {
    background: #232323;
  }
  .mx-task-top-links {
    .mx-task-top-link {
      &-active {
        color: #126d67;
        border-color: #126d67;
        &:hover {
          color: #0f6963;
          border-color: #0f6963;
        }
      }
    }
  }
}

</style>
