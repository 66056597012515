<template>
  <v-container fluid>
  <div id="lprs" :style="`position: relative; min-height: ${lprs_height}px;`">
    <mx-preloader :z-index="7" :logo="true" :spiner="true" :loader-status="preloader === true" style="height: calc(100vh - 80px); margin-top: -12px;"/>
    <v-row class="mt-4">
      <v-col cols="12" class="d-flex">
        <v-btn
            style="padding: 20px 0 21px 0;"
            class="mr-7"
            elevation="2"
            color="primary"
            medium
            :to="{name: 'companies'}"
        >
          <v-icon size="24">fas fa-angle-left</v-icon>
        </v-btn>
        <h2 class="d-flex flex-column">
          <strong style="font-size: 22px; margin-top: -6px;">{{ company.company_name }}</strong>
          <span style="font-size: 18px; font-weight: 400; margin-top: -8px;">{{ customer.first_name }} {{ customer.last_name }}</span>
        </h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-tabs :ripple="false" v-model="tab" fixed-tabs>
            <v-tabs-slider :ripple="false" color="primary"></v-tabs-slider>
            <v-tab :disabled="item.disabled" v-if="item.can" :ripple="false" v-for="item in items" :key="item.id">
              {{ item.name }}
            </v-tab>
          </v-tabs>
          <v-tabs-items id="tbsh" v-model="tab" :style="`height: ${tbsh_height}px;`">
            <v-tab-item class="pa-6 pt-2" style="height: 100%; overflow-y: auto">
              <v-row style="height: 100%;">
                <template>
                  <v-col cols="12" lg="6" xl="4">
                    <CompanyCard :company="company" />
                  </v-col>
                  <v-col cols="12" lg="6" xl="4">

                    <v-card flat class="pa-2">
                      <v-card-title>
                        <v-icon class="mt-1">mdi-shield-account-outline</v-icon>
                        <v-spacer></v-spacer>
                        <span style="font-weight: 300;" class="mr-2">Ansprechpartner</span>
                      </v-card-title>
                      <v-divider></v-divider>
                      <v-row v-if="contact_peoples.length > 0" class="row mt-7">
                        <v-col v-for="contact in contact_peoples" :key="contact.id" cols="12" class="mb-n2">
                          <div class="mx-card" style="border: 1px solid #cecece; padding: 12px; border-radius: 6px; color: rgba(0, 0, 0, 0.6)">
                            <div style="display: flex; align-items: center">
                              <img :src="options.avatar_url + contact.avatar" height="100" style="border-radius: 6px" class="img-fluid img-thumbnail">
                              <div style="display: flex; flex-direction: column; flex-grow: 1; padding: 0 15px 0 20px;">
                                <strong style="font-size: 15px; font-weight: 500; color: rgba(0, 0, 0, 0.6); border-bottom: 1px solid #cecece; padding-bottom: 3px; margin-bottom: 0;">
                                  {{ contact.first_name }} {{ contact.last_name }}
                                </strong>
                                <div >
                                  <strong style="font-weight: 500; font-size: 13px; width: 100%; display: inline-block; padding: 0;">
                                    {{ contact.position }}
                                  </strong>
                                </div>
                                <div style="font-size: 13px;" class="mt-2">
                                  <div class="mt-auto">
                                    <span style="margin-right: 5px;">Email: </span>
                                    <a :href="`mailto:${contact.contact_email}`">{{ contact.contact_email }}</a>
                                  </div>
                                  <div class="mb-1">
                                    <span style="margin-right: 5px;">Telefon:</span>
                                    <strong>{{ contact.contact_phone }}</strong>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </v-col>
                      </v-row>
                    </v-card>

                  </v-col>
                </template>

                <v-col style="margin-top: auto;" cols="12">
                  <v-card flat style="min-height: 86px;">
                    <v-card-title>
                      <v-icon class="mr-2">mdi-clipboard-account-outline</v-icon>
                      <span style="font-weight: 300;">{{ customer.first_name }} {{ customer.last_name }}</span>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-subtitle>
                      <strong>{{ $t('customer.show.gender.title') }}:</strong> <span>{{ $t(`customer.show.gender.${customer.gender === 'male' ? 'male_hr' : customer.gender }`) }} {{ customer.first_name }} {{ customer.last_name }}</span> <br>
<!--                      <strong>{{ $t('customer.show.full_name') }}:</strong> <span>{{ customer.first_name }} {{ customer.last_name }}</span> <br>-->
<!--                      <strong>{{ $t('customer.show.identification') }}:</strong> <span>{{ customer.identification }}</span> <br>-->
                      <strong>{{ $t('customer.show.email') }}:</strong> <span><a :href= "`mailto:${customer.email}`">{{ customer.email }}</a></span> <br>
                    </v-card-subtitle>
                  </v-card>
                </v-col>
              </v-row>
            </v-tab-item>

            <v-tab-item class="pa-6" style="height: 100%; overflow-y: auto">
              <v-row style="height: 100%;">
                <template>
                  <v-col cols="12">
                    <TableFullPersons :contact_persons="contact_persons" />
                  </v-col>
                </template>
              </v-row>
            </v-tab-item>

            <v-tab-item class="pa-6" style="height: 100%; overflow-y: auto">
              <v-row style="height: 100%;">
                <template>
                  <v-col cols="12">
                    <TableFullAddresses :addresses="company_addresses" />
                  </v-col>
                </template>
              </v-row>
            </v-tab-item>

            <v-tab-item class="pa-6" style="height: 100%; overflow-y: auto">
              <v-row style="height: 100%;">
                <template>
                  <v-col cols="12">
                    <CustomerDocuments :customer="customer" />
                  </v-col>
                </template>
              </v-row>
            </v-tab-item>

            <v-tab-item class="px-6" style="height: 100%; overflow-y: auto">
              <v-row style="height: 100%;">
                <template v-if="!preloader">
                  <v-col class="align-center justify-center d-flex flex-column" cols="12">
                    <h2 class="mt-8">
                      {{titleReplace(faq.welcome_text)}}
                    </h2>
                    <v-btn v-if="questions.length === 0" @click="getQuestions" x-large class="mt-16" color="primary">
                      {{ $t('faq.question.get') }}
                    </v-btn>
                  </v-col>
                </template>
                <template v-if="questions.length > 0">
                  <v-col cols="12">
                    <QuestionsList :questions="questions"/>
                  </v-col>
                </template>
              </v-row>
            </v-tab-item>

            <v-tab-item class="pa-6" style="height: 100%; overflow-y: auto">
              <v-row style="height: 100%;">
                <template>
                  <v-col cols="12">
                    <TableFullCustomerAccessData :cads="cads" />
                  </v-col>
                </template>
              </v-row>
            </v-tab-item>

            <v-tab-item class="pa-6" style="height: 100%; overflow-y: auto">
              <v-row style="height: 100%;">
                <template>
                  <v-col cols="12">
                    <NoticeList :customer_id="customer.id" :notices="notices" />
                  </v-col>
                </template>
              </v-row>
            </v-tab-item>

            <v-tab-item class="pa-6" style="height: 100%; overflow-y: auto">
              <v-row style="height: 100%;">
                <template>
                  <v-col cols="12">
                    <Conversation :company_id="$route.params.company_id" />
                  </v-col>
                </template>
              </v-row>
            </v-tab-item>

            <v-tab-item class="pa-6" style="height: 100%; overflow-y: auto">
              <v-row style="height: 100%;">
                <template>
                  <v-col cols="12">
                    <CustomerTasks v-if="tab === 8" :customer="customer" />
                  </v-col>
                </template>
              </v-row>
            </v-tab-item>

          </v-tabs-items>
        </v-card>
      </v-col>
    </v-row>
  </div>
  </v-container>
</template>

<script>

import CompanyCard from "@/components/Company/CompanyCard";
import TableFullPersons from "@/components/Tables/TableFullPersons";
import TableFullAddresses from "@/components/Tables/TableFullAddresses";
import QuestionsList from "@/components/Faq/QuestionsList";
import TableFullCustomerAccessData from "@/components/Tables/TableFullCustomerAccessData";
import CustomerDocuments from "@/components/Dokumente/CustomerDocuments";

import NoticeList from "@/module/notice/components/NoticeList";
import Conversation from "@/module/conversation/views/MainView";
import CustomerTasks from "@/module/task/components/CustomerTasks";

import {can} from "@/middleware/access_middleware";
import axios from "axios";

export default {
  components: { CompanyCard, TableFullPersons, TableFullAddresses, QuestionsList, TableFullCustomerAccessData, CustomerDocuments, NoticeList, Conversation, CustomerTasks },
  props: {
    company_id: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      page: 1,
      preloader: true,
      tbsh_height: 0,
      lprs_height: 0,
      customer: {},
      tab: null,
      items: [
        { id: 1, name: this.$t('tabs.companies'), disabled: false, can: can('customer-company-show')},
        { id: 2, name: this.$t('tabs.employee'), disabled: false, can: can('customer-employee-show')},
        { id: 3, name: this.$t('tabs.address'), disabled: false, can: can('customer-location-show')},
        { id: 4, name: this.$t('tabs.documents'), disabled: false, can: can('customer-document-show')},
        { id: 5, name: this.$t('tabs.faq'), disabled: false, can: can('customer-faq-show')},
        { id: 6, name: this.$t('tabs.cad'), disabled: false, can: can('customer-access-data-show')},
        { id: 7, name: this.$t('tabs.notice'), disabled: false, can: true}, // can: can('customer-notice-show')
        { id: 8, name: this.$t('tabs.conversation'), disabled: false, can: true}, // can: can('customer-notice-show')
        { id: 9, name: this.$t('tabs.tasks'), disabled: false, can: true}, // can: can('customer-notice-show')
      ],
      company: {},
      faq: [],
      questions: [],
      contact_persons: [],
      contact_peoples: [],
      company_addresses: [],
      cads: [],
      notices: [],
      company_load: false
    }
  },
  created() {
    setTimeout(this.MxHeight, 1)
    // setTimeout(this.mxLoader, 300)
  },
  watch: {
    company_id() {
      this.getCompany()
    },
    tab() {
      if (this.tab === 6) {
        this.getAllNotices(this.customer.id)
      }
    }
  },
  mounted() {
    this.MxHeight()
    this.getCompany()
  },
  computed: {

  },
  methods: {
    getAvatar(avatar) {
      return (avatar !== null) ?  this.options.avatar_url + avatar : '/assets/images/avatar.jpg'
    },
    titleReplace(text) {
      if (text === null) {
        return text
      }

      let title_replace = text.replace('<Vorname>', this.$store.getters.user.first_name)
      title_replace = title_replace.replace('<Nachname>', this.$store.getters.user.last_name)

      return title_replace
    },
    getCompany() {
      if (this.$route.params.company_id !== null) {
        this.$store.dispatch('companyShow', this.$route.params.company_id).then(() => {
          this.company = this.$store.getters.company
          this.customer = this.$store.getters.company.customer
          this.faq = this.$store.getters.company.faq
          this.contact_persons = this.$store.getters.company.persons
          this.company_addresses = this.$store.getters.company.addresses
          this.cads = this.$store.getters.company.customer_access_data

          axios.get(`/customers-contact-person-gateway/${this.customer.id}`).then(response => {
            this.contact_peoples = response.data.data
          })

          if (this.cads.length === 0) {
            this.items[5].disabled = true
          }

          if (this.customer.can_faq === 1) {
            this.items[4].disabled = false
          } else {
            if (this.tab === 4) {
              this.tab = 0
            }
            this.items[4].disabled = true
          }
          setTimeout(this.mxLoader, 100)
        })
      }
    },
    getQuestions() {
      this.$store.dispatch('questionPerFaqId', this.company.faq.id).then(() => {
        this.questions = this.$store.getters.company.faq.questions
      })
    },
    mxLoader() {
      this.preloader = false
    },
    MxHeight() {
      this.lprs_height = window.innerHeight - (document.getElementById('lprs').getBoundingClientRect().top + 20)
      this.tbsh_height = window.innerHeight - (document.getElementById('tbsh').getBoundingClientRect().top + 20)
    },
    async getAllNotices(customer_id) {
      let customers = this.$store.getters.companies.data
      if (undefined === customers) {
        await this.$store.dispatch('companies', 1).then(() => {
          customers = this.$store.getters.companies.data
        })
      }

      await this.$store.dispatch('noticesFromCustomer', {customer_id: customer_id, page: 1}).then(() => {
        this.notices = this.$store.getters.notices
        this.notices = this.notices.map(n => {

          let customer = customers.find(c => c.customer_id === n.customer_id)
          if (undefined !== customer) {
            let cm = customer.customer
            n.customer = cm.first_name + ' ' + cm.last_name
          }
          return n;
        })
      })
    }
  }
}
</script>
