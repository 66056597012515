<template>
  <div>
    <mx-preloader style="margin-top: 16px;" :z-index="7" :logo="false" :spiner="true" :loader-status="preloader === true"/>
    <v-row class="mb-4">
      <v-col cols="12" sm="10">
        <v-text-field
            :label="$t('customer.search.label')"
            :placeholder="$t('customer.search.placeholder')"
            v-model="search"
            solo
            hide-details
        ></v-text-field>
      </v-col>
      <v-col class="ml-md-n5" cols="12" sm="2">
        <v-btn
            style="padding: 24px 0 25px 0;"
            elevation="2"
            color="primary"
            medium
            class="ml-1"
            @click="searchClear"
        >
          <v-icon size="32">mdi-close</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row id="card_questions" :style="`overflow: auto; position: relative; min-height: ${height}px;  max-height: ${height}px;`">
      <v-col cols="12">
        <v-card flat class="my-2">
          <v-card elevation="4" class="mb-4 py-6 d-flex" v-for="(item, index) in filterQuestions" :key="index">
            <v-card flat class="px-8 py-4" style="min-width: 320px; max-width: 320px; border-right: 1px solid #cecece; border-radius: 0; min-height: 70px">
              {{item.title}}
            </v-card>
            <v-card flat class="py-4 px-10 d-flex flex-column flex-grow-1 rounded-0">
              <v-card flat class="mb-1">
                <span v-html="brs(item.text)"></span>
              </v-card>
              <v-card v-if="item.notice !== null" flat color="primary lighten-4" class="rounded-1 py-1 px-2">{{ item.notice }}</v-card>
            </v-card>
          </v-card>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    questions: {
      type: Array,
      default: []
    }
  },
  data() {
    return {
      preloader: true,
      search: '',
      items: [],
      height: 0
    }
  },
  watch: {
    questions() {
      this.getItems()
    }
  },
  created() {
    setTimeout(this.cardHeight, 300)
    this.getItems()
  },
  mounted() {
    this.cardHeight()
    this.onResize()
    window.addEventListener('resize', this.onResize, {passive: true})
  },
  beforeDestroy() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.onResize)
    }
  },
  computed: {
    filterQuestions() {
      return this.questions.filter(question => {
        return question.title.toLowerCase().indexOf(this.search.toLowerCase()) !== -1
      })
    }
  },
  methods: {
    brs(text) {
      return text.replace(/\n/g, '<br />')
    },
    onResize() {
      this.isMobile = window.innerWidth < 600
    },
    cardHeight() {
      this.height = window.innerHeight - (document.getElementById('card_questions').getBoundingClientRect().top - 40)
      setTimeout(this.mxPreloader, 700)
    },
    getItems() {
      this.items = this.questions
    },
    searchItem() {
      if (this.search !== null) {
        this.items = this.questions.filter(question => {
          return question['title'].toLowerCase().indexOf(this.search.toLowerCase()) !== -1
        })
      }
    },
    searchClear() {
      this.search = ''
    },
    mxPreloader() {
      this.preloader = false
    }
  }
}
</script>

<style scoped>

</style>